import { AlbumPageViewBinder } from '@sqs/site-rendering/AlbumPlayer';

/**
 * @module squarespace-album-player
 */
YUI.add('squarespace-album-player', function (Y) {
  var albumInstances = [];

  var sync = function(albumBox) {
    var albumImage = albumBox.one('.album-cover img');
    if (albumImage) {
      Y.config.win.ImageLoader.load(albumImage, { load: true });
    }
    albumBox.removeClass('loading');
  };

  Y.on('windowresize', function() {
    Y.all('.sqs-audio-playlist').each(function(albumBox) {
      sync(albumBox);
    });
  });

  Y.config.win.Squarespace.onInitialize(Y, function() {
    Y.all('.sqs-audio-playlist').each(function(albumBox) {
      var albumInstance = new AlbumPageViewBinder();
      albumInstances.push(albumInstance);
      albumInstance.connectToNode(albumBox.getDOMNode());
      sync(albumBox);
    });
  });

  Y.config.win.Squarespace.onDestroy(Y, function() {
    albumInstances.forEach(function(albumInstance) {
      albumInstance.destroy();
    });
    albumInstances.length = 0;
  });

}, '1.0', {
  requires: [
    'event-resize'
  ]
});
