import { Keyboard } from '@sqs/universal-utils';
import clamp from '@sqs/utils/number/clamp';

const {
  isArrowLeftKey,
  isArrowUpKey,
  isArrowRightKey,
  isArrowDownKey
} = Keyboard;

// These constants define the speed with which
// the scrubber advances when a key is pressed.
// There's nothing special about these constants,
// just a guess at what would be usable.
// The speed is supposed to increase.
// The UX is borrowed from Able Player
// (https://ableplayer.github.io/ableplayer/demos/audio1.html).
const REPEAT_MULTIPLIERS = [1, 2, 5, 10, 60];
const REPEAT_MULTIPLIER_USAGE = 10;

/**
 * @param {HTMLElement|Y.Node} element The keyboard-focusable element
 *                                     that event handlers should be attached to
 * @param {function} getDuration Function returning track duration
 * @param {function} getPosition Function returning current track position
 * @param {function} isPlaying Function returning whether player is currently playing
 * @param {function} play Function transitioning player to the playing state
 * @param {function} pause Function transitioning player to the paused state
 * @param {function} setPosition Function setting track position.
 *                               Accepts one argument (new position, in seconds).
 * @return {function} Cleanup function, removing event handlers
 */
export default function registerKeyboardScrubber({
  element,
  getDuration,
  getPosition,
  isPlaying,
  play,
  pause,
  setPosition
}) {
  // Until key is released or element loses focus,
  // currentlyPressedKeyCode holds the key code
  // of the currently pressed key.
  let currentlyPressedKeyCode = null;

  // Whether track was playing before scrubbing started.
  let wasPlaying;

  // Counter increases on each keydown event while
  // the same key is pressed. The UX is to increase
  // scrubbing speed while a key stays pressed.
  let counter;

  const onKeyDown = (e) => {
    let direction;

    if (isArrowLeftKey(e) || isArrowDownKey(e)) {
      direction = -1;
    } else if (isArrowRightKey(e) || isArrowUpKey(e)) {
      direction = 1;
    } else {
      return;
    }

    // If default is not prevented, document may scroll.
    e.preventDefault();

    // No arrow key was pressed, or a second arrow key is being pressed.
    if (e.keyCode !== currentlyPressedKeyCode) {
      // Scrubbing interaction is beginning
      // (this is the first arrow key being pressed),
      // need to store current playing state
      // to restore it later.
      if (currentlyPressedKeyCode === null) {
        wasPlaying = isPlaying();
        if (wasPlaying) {
          pause();
        }
      }
      // Regardless if any keys were pressed before,
      // a new scrubbing needs to start
      // (by storing key code and setting counter to 0,
      // reducing scrubbing speed to initial).
      currentlyPressedKeyCode = e.keyCode;
      counter = 0;
    }

    const multiplierIndex = Math.min(REPEAT_MULTIPLIERS.length - 1, Math.floor(counter / REPEAT_MULTIPLIER_USAGE));
    const multiplier = REPEAT_MULTIPLIERS[multiplierIndex];
    const updatedPosition = clamp(getPosition() + direction * multiplier, 0, getDuration());
    setPosition(updatedPosition);

    counter++;
  };

  const stopScrubbing = () => {
    if (currentlyPressedKeyCode !== null) {
      currentlyPressedKeyCode = null;
      if (wasPlaying) {
        play();
      }
    }
  };

  const onKeyUp = (e) => {
    if (e.keyCode === currentlyPressedKeyCode) {
      stopScrubbing();
    }
  };

  const onBlur = stopScrubbing;

  const rawElement = typeof element.getDOMNode === 'function' ?
    element.getDOMNode() :
    element;

  rawElement.addEventListener('keydown', onKeyDown);
  rawElement.addEventListener('keyup', onKeyUp);
  rawElement.addEventListener('blur', onBlur);

  return function deregister() {
    rawElement.removeEventListener('keydown', onKeyDown);
    rawElement.removeEventListener('keyup', onKeyUp);
    rawElement.removeEventListener('blur', onBlur);
  };
}
