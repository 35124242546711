/* eslint-disable */
// Generated from seed-templates/handlebars-module-template.js
YUI.add('squarespace-block-audio-player-template', function(Y) {
  var Handlebars = Y.Handlebars;

  (function() { var template = Handlebars.template, templates = Handlebars.templates = Handlebars.templates || {}; templates["block-audio-player.html"] = template(function (Handlebars,depth0,helpers,partials,data) {
  this.compilerInfo = [4,'>= 1.0.0'];
helpers = this.merge(helpers, Handlebars.helpers); data = data || {};
  var buffer = "", stack1, functionType="function", escapeExpression=this.escapeExpression;


  buffer += "<div class=\"player\">\n  <div\n    class=\"action\"\n    tabindex=\"0\"\n  >\n    <div class=\"play\">\n      <div class=\"play-button\"></div>\n    </div>\n    <div class=\"pause\">\n      <div class=\"pause-button\"></div>\n    </div>\n  </div>\n  <div class=\"track\">\n    <span class=\"played\"><span class=\"icon\"></span></span>\n  </div>\n  <div class=\"labels\">\n    <div class=\"title-wrapper\">\n      <div class=\"title\" title=\"";
  if (stack1 = helpers.title) { stack1 = stack1.call(depth0, {hash:{},data:data}); }
  else { stack1 = depth0.title; stack1 = typeof stack1 === functionType ? stack1.apply(depth0) : stack1; }
  buffer += escapeExpression(stack1)
    + "\" data-title=\"";
  if (stack1 = helpers.title) { stack1 = stack1.call(depth0, {hash:{},data:data}); }
  else { stack1 = depth0.title; stack1 = typeof stack1 === functionType ? stack1.apply(depth0) : stack1; }
  buffer += escapeExpression(stack1)
    + "\">";
  if (stack1 = helpers.title) { stack1 = stack1.call(depth0, {hash:{},data:data}); }
  else { stack1 = depth0.title; stack1 = typeof stack1 === functionType ? stack1.apply(depth0) : stack1; }
  buffer += escapeExpression(stack1)
    + "</div>\n    </div>\n    <div class=\"artistName\" title=\"";
  if (stack1 = helpers.artistName) { stack1 = stack1.call(depth0, {hash:{},data:data}); }
  else { stack1 = depth0.artistName; stack1 = typeof stack1 === functionType ? stack1.apply(depth0) : stack1; }
  buffer += escapeExpression(stack1)
    + "\">";
  if (stack1 = helpers.artistName) { stack1 = stack1.call(depth0, {hash:{},data:data}); }
  else { stack1 = depth0.artistName; stack1 = typeof stack1 === functionType ? stack1.apply(depth0) : stack1; }
  buffer += escapeExpression(stack1)
    + "</div>\n  </div>\n  <div class=\"secondary-controls\">\n    <div class=\"time\">\n      <span class=\"progress\"></span>\n      <span class=\"total\">";
  if (stack1 = helpers.duration) { stack1 = stack1.call(depth0, {hash:{},data:data}); }
  else { stack1 = depth0.duration; stack1 = typeof stack1 === functionType ? stack1.apply(depth0) : stack1; }
  buffer += escapeExpression(stack1)
    + "</span>\n    </div>\n  </div>\n</div>\n";
  return buffer;
  });})()

  var filename = 'block-audio-player.html';
  Y.Handlebars.registerPartial(filename.replace('/', '.'), Handlebars.templates[filename]);

}, '1.0', {
  requires: [
    "handlebars-base"
  ]
});
