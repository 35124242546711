import { t } from 'shared/i18n';
import humanizeDuration from 'shared/utils/humanizeDuration';
import TrackManager from '@sqs/site-rendering/TrackManager';
import * as UrlUtils from 'shared/utils/UrlUtils';
import { Keyboard } from '@sqs/universal-utils';

var isArrowLeftKey = Keyboard.isArrowLeftKey;
var isArrowUpKey = Keyboard.isArrowUpKey;
var isArrowRightKey = Keyboard.isArrowRightKey;
var isArrowDownKey = Keyboard.isArrowDownKey;
import registerKeyboardScrubber from 'shared/utils/KeyboardScrubber';

import clamp from '@sqs/utils/number/clamp';

// eslint-disable-next-line import/no-unresolved
import '../styles-compressed/legacy/audio-player.css';

/**
* THIS IS AN OLD AUDIO PLAYER THAT WILL BE REMOVED ONCE THE NEW AUDIO PLAYER
* IS LIVE.
* -naz
*
* NO IT WONT BECAUSE WE STILL LET PEOPLE CHOOSE IT AS AN OPTION
* -jboilen
*
* -----------------------------------
* Holds the squarespace-audio player widget, used on both the front and back end
*/
YUI.add('squarespace-audio-player', function (Y) {
  /**
    A simple audio player widget that uses soundmanaget to play audio from
    a supplied url.
    @class AudioPlayer
    @namespace Squarespace.Widgets
    @constructor
    @extends Squarespace.Widgets.SSWidget
  */
  Y.namespace('Squarespace.Widgets');
  var AudioPlayer =
  Y.Squarespace.Widgets.AudioPlayer =
  Y.Base.create('audioPlayer', Y.Squarespace.Widgets.SSWidget, [], {

    initializer: function (config) {
      // grab node this should render into
      var node = this.get('render');
      if (Y.Lang.isBoolean(node)) {
        node = null;
      } else if (Y.Lang.isString(node)) {
        node = Y.one(node);
      }

      // destroy other instances
      if (node) {
        if (node._audioPlayer) {
          node._audioPlayer.destroy();
        }
        node._audioPlayer = this;
        // initialize data from the node, if we have it (apply attrs from data attributes)
        Y.Object.each(node.getData(), function (value, attrName) {
          if (value) {
            this.set(attrName, value);
          }
        }, this);
      }
    },

    /**
      Destroys the sound manager sound when this widget is destroyed. SSWidget handles cleanup of events
      under this yuid category, and animations in this._anims
      @method destructor
    */
    destructor: function () {
      if (this._audio) {
        this.stop();
        this._audio = null;
      }

      TrackManager.player.pause();

      if (this._deregisterKeyboardScrubber) {
        this._deregisterKeyboardScrubber();
      }
    },

    // Public methods
    /**
      See YUI widget documentation for information on renderUI
      @method renderUI
    */
    renderUI: function () {
      AudioPlayer.superclass.renderUI.apply(this, arguments);

      var boundingBox = this.get('boundingBox');

      // set initial placeholder text
      var placeholderNode = boundingBox.one('.placeholder');
      var title = this.get('title'),
      author = this.get('author');

      if (title && author) {
        placeholderNode.setContent(Y.Node.create('<div class="wrapper"><div class="audio-author">' + author +
        '</div><div class="audio-title">' + title + '</div></div>'));
      } else if (title) {
        placeholderNode.setContent(Y.Node.create('<div class="wrapper"><div class="audio-title">' + title +
        '</div></div>'));
      } else {
        placeholderNode.setContent(Y.Node.create('<div class="wrapper">' + this.get('strings.placeholder') + '</div>'));
      }

      if (this.get('show-download') === 'true' && this.get('url')) {
        var url = UrlUtils.addQueryParam(this.get('url'), 'download', 'true');
        var markup = '<div class="download">' +
        '<a href="' + url + '" target="_blank">' + this.get('strings.download') + '</a>' +
        '</div>';

        this.get('contentBox').append(markup);
        boundingBox.addClass('download');
      }

      // start the progress bar off at nothing
      boundingBox.one('.progress-inner').setStyle('width', '0px');

      // render volume bars
      var volumeNode = this._volumeNode = boundingBox.one('.volume');

      if (!volumeNode) {
        throw new Error(this.name + ': No div.volume in template.');
      }

      var volumeLevels = this.get('volumeLevels');

      for (var i = volumeLevels; i > 0; i--) {
        volumeNode.prepend('<div class="bar level-"' + i + '" data-volume-level="' + i + '"></div>');
      }

      boundingBox.one('.duration').setContent(this.get('strings.durationPlaceHolder'));
    },

    /**
      See YUI widget documentation for information on bindUI
      @method bindUI
    */
    bindUI: function () {
      var id = this.get('id');
      var boundingBox = this.get('boundingBox');

      boundingBox.one('.controls').on('keydown', function (e) {
        var enterPressed = e.keyCode === 13;
        var spacePressed = e.keyCode === 32;
        if ((enterPressed || spacePressed) && !this.get('disabled')) {
          e.halt();
          this._onControlsButtonClick();
        }
      }, this);

      // interactions
      boundingBox.on(id + '|click', this._onClick, this);

      // respond to volume attribute changes
      this.after(id + '|volumeChange', function (e) {
        this._setVolume(e.newVal);
      }, this);

      // update wrapper class when playstate changes
      this.after(id + '|playStateChange', this._uiUpdateWrapperClass, this);

      this.after(id + '|readyStateChange', this._afterReadyStateChange, this);

      // disabling widget will stop music
      this.after(id + '|disabledChange', function (e) {
        var disabling = e.newVal;
        if (disabling) {
          this.stop();
        }
      }, this);

      // listen for global stop all events
      Y.on(id + '|audioPlayer:stopAll', function (e) {
        var container = e ? e.container : null;
        if (container && Y.Lang.isString(container)) {
          container = Y.one(container);
        }

        if (
        !e ||
        !e.hasOwnProperty('container') ||
        container instanceof Y.Node && container.contains(this.get('boundingBox')))
        {
          this.stop();
        }
      }, this);

      this._deregisterKeyboardScrubber = registerKeyboardScrubber({
        element: this.get('boundingBox').one('.progress'),
        getDuration: function () {
          return this._audio.duration;
        }.bind(this),
        getPosition: function () {
          return this._audio.position;
        }.bind(this),
        isPlaying: function () {
          return this.get('playState') === this.getProperty('PLAY_STATES').PLAYING;
        }.bind(this),
        play: this.play.bind(this),
        pause: this.pause.bind(this),
        setPosition: function (position) {
          this.seek(position / this._audio.duration * 100);
        }.bind(this) });


      var volumeControl = this.get('boundingBox').one('.volume');

      volumeControl.on('keydown', function (e) {
        var direction;

        if (isArrowLeftKey(e) || isArrowDownKey(e)) {
          direction = -1;
        } else if (isArrowRightKey(e) || isArrowUpKey(e)) {
          direction = 1;
        } else {
          return;
        }

        // If default is not prevented, document may scroll
        e.preventDefault();

        var currentVolumeLevel = this._getVolumeLevel();
        var newVolumeLevel = clamp(currentVolumeLevel + direction, 0, this.get('volumeLevels'));
        this._setVolumeLevel(newVolumeLevel);
      }, this);
    },

    /**
      See YUI widget documentation for information on syncUI
      @method syncUI
    */
    syncUI: function () {
      this._uiUpdateWrapperClass();
      this._uiUpdateVolumeLevel();

      var boundingBox = this.get('boundingBox');

      if (boundingBox.ancestor('.sqs-editing')) {
        TrackManager.player.pause();
        this.pause();
      }

      boundingBox.toggleClass('tiny', boundingBox.get('offsetWidth') < 300);
    },

    /**
      Plays the sound at the given URL
      @method play
    */
    play: function () {
      var playStates = this.getProperty('PLAY_STATES');

      TrackManager.player.setVolume(this.get('volume') / 100);


      if (!this._audio) {
        this._registerTrack();
        this._audio.play();
      } else if (this.get('playState') !== playStates.PLAYING) {
        this._audio.play();
      }

      this._set('playState', playStates.PLAYING);
    },

    /**
      Pauses the currently playing sound in this widget
      @method pause
    */
    pause: function () {
      if (this._audio) {
        this._audio.pause();
      }
    },

    /**
      Stops the currently playing sound in this widget
      @method stop
    */
    stop: function () {
      var playState = this.get('playState'),
      playStates = this.getProperty('PLAY_STATES');

      if (playState === playStates.PLAYING && this._audio) {
        this._audio.pause();
      }
    },

    /**
      This methods allows you to jump to a specific location in the music and begin
      playing from that point
      @method seek
      @param percentage {Number} A percentage (0-100) along the track to seek to
    */
    seek: function (percentage) {
      if (percentage > 100 || percentage < 0) {
        console.warn(this.name + ': seek() - Percentage between 0-100 required.');
        return;
      }
      if (!this._audio) {
        this._registerTrack();
        this.play();
      }
      this._audio.seek(this._audio.duration * (percentage / 100)); // seek by percentage
    },

    // Public convenience methods
    /**
      Convenience method for setting the volume of the widget
    */
    setVolume: function (value) {
      this.set('volume', value);
    },

    /**
      Sets the volume of the underlying sound manager object and updates
      the UI to match
      @method _setVolume
      @private
      @param percentage {Number} A percentage (0-100) to set the volume to
    */
    _setVolume: function (percentage) {
      // If tree changes volume in the woods, and no one is
      // around to hear it...

      if (this._audio) {
        TrackManager.player.setVolume(percentage / 100);
      }

      this._set('volume', percentage);

      this._uiUpdateVolumeLevel();
    },

    /**
      Sets the volume using a level instead of a percentage. Levels are
      from 0 to volumeLevels attribute
      @method _setVolumeLevel
      @private
      @param level {Number} A level from 0-volumeLevels
    */
    _setVolumeLevel: function (level) {
      this._setVolume(Math.round(level / this.get('volumeLevels') * 100));
    },

    /**
      Initializes the underlying audio.
      @method _registerTrack
      @private
      @return {Boolean} True if the underlying sound was initialized
        and false if it wasn't initialized
    */
    _registerTrack: function () {
      if (!this._audio) {

        // Set ui to loading
        var boundingBox = this.get('boundingBox');
        var url = this.get('fixedUrl');

        boundingBox.addClass('loading');

        // ...or if there is no url
        if (!url) {
          this._showError(t("No URL provided."));


          return;
        }

        this._audio = TrackManager.createTrack(url);
        this._audio.on('pause', Y.bind(this._onPause, this));
        this._audio.on('ended', Y.bind(this._onStop, this));
        this._audio.on('inactive', Y.bind(this._onPause, this));
        this._audio.on('positionChange', Y.bind(this._onWhilePlaying, this));
        this._audio.on('ready', Y.bind(this._onLoad, this));

        return !!this._audio;
      }
      return false;
    },

    _getVolumeLevel: function () {
      var volumeLevels = this.get('volumeLevels'),
      volume = this.get('volume');
      return Math.round(volume / 100 * volumeLevels);
    },

    // private UI update methods
    /**
      Updates the UI to reflect the actual current volume
      @method _uiUpdateVolumeLevel
      @private
    */
    _uiUpdateVolumeLevel: function () {

      var volumeBarNodes = this._volumeNode.get('children'),
      currentLevel = this._getVolumeLevel();

      volumeBarNodes.each(function (volumeBarNode) {
        var barLevel = parseInt(volumeBarNode.getAttribute('data-volume-level'), 10);

        if (barLevel <= currentLevel) {
          volumeBarNode.addClass('active');
        } else {
          volumeBarNode.removeClass('active');
        }
      }, this);
    },

    /**
      Updates the wrapper class of the bounding box/wrapper to match the
      current playState of the widget
      @method _uiUpdateWrapperClass
    */
    _uiUpdateWrapperClass: function () {
      // get all wrapper classes
      var readyStates = this.getProperty('READY_STATES'),
      playStates = this.getProperty('PLAY_STATES'),
      boundingBox = this.get('boundingBox'),
      currentClasses = boundingBox.getAttribute('class').split(' ');

      var state;
      for (state in playStates) {
        currentClasses.remove('play-state-' + playStates[state]);
      }

      for (state in readyStates) {
        currentClasses.remove('ready-state-' + readyStates[state]);
      }

      boundingBox.addClass('ready-state-' + this.get('readyState'));

      boundingBox.
      setAttribute('class', currentClasses.join(' ')).

      addClass('play-state-' + this.get('playState')).
      addClass('ready-state-' + this.get('readyState'));
    },

    // Dom Event and attribute listeners
    /**
      Handles clicks anywhere in the audio player widget container
      @method _onClick
      @private
      @param e {Event} The click event
    */
    _onClick: function (e) {

      if (Y.UA.ios) {
        window.location.href = this.get('url');
        return;
      }

      if (e.target.ancestor().hasClass('download')) {
        return;
      }

      e.halt();
      e.stopImmediatePropagation();

      // don't do anything if the widget is disabled
      if (this.get('disabled')) {
        return;
      }

      // ignore clicks on duration area
      if (e.target.hasClass('duration')) {
        return;
      }

      // handle changing colume case
      if (e.target.ancestor('.volume')) {
        this._setVolumeLevel(
        parseInt(e.target.getAttribute('data-volume-level'), 10));

        return;
      }

      // click to play
      if (e.target.ancestor('.placeholder', true)) {
        this.play();
        return;
      }

      // Start/stop case
      if (e.target.ancestor('.controls', true)) {
        this._onControlsButtonClick();
      }

      // seek case
      var progressRegion = this.get('boundingBox').one('.progress').get('region');
      if (e.pageX >= progressRegion.left && e.pageX <= progressRegion.right) {
        this.seek((e.pageX - progressRegion.left) / progressRegion.width * 100);
      }

    },

    _onControlsButtonClick: function () {
      var playState = this.get('playState'),
      playStates = this.getProperty('PLAY_STATES');

      switch (playState) {
        case playStates.PLAYING:
          this.pause();
          return;
        case playStates.PAUSED:
        case playStates.STOPPED:
          this.play();
          return;
        default:
          if (__DEV__) {
            console.warn(this.name + ': Wait, what? How did you get here? Case was ', playState);
          }}

    },

    /**
    *
      @method _afterReadyStateChange
      @private
      @param e {Event} The atribute change event
    */
    _afterReadyStateChange: function (e) {
      var readyStates = this.getProperty('READY_STATES'),
      currentState = this.get('readyState'),
      strings = this.get('strings'),
      errorMessage = false;

      switch (currentState) {

        case readyStates.INITIALIZED:
          // its all good
          this.enable();
          break;

        case readyStates.FLASH_BLOCKED:
          errorMessage = strings.soundManagerFlashFail;
          break;

        case readyStates.SECURITY_ERROR:
          errorMessage = strings.soundManagerSecurityFail;
          this._reportAudioError(readyStates.SECURITY_ERROR);
          break;

        case readyStates.SOUND_LOAD_FAILED:
          errorMessage = strings.soundManagerURLFail;
          this._reportAudioError(readyStates.SOUND_LOAD_FAILED);
          break;

        case readyStates.SOUND_LOADED:
          // do nothing, its all good
          break;

        default:
          console.warn(this.name + ': Invalid ready state (' + currentState + ').');
          break;}


      if (errorMessage) {
        if (__DEV__) {
          console.warn('AUDIO ERROR: ' + errorMessage);
        }
        this._showError(errorMessage);
        this.disable();
      }

      this._uiUpdateWrapperClass();
    },

    _reportAudioError: function (error) {
      try {
        var url = this.get('url');
        if (url.indexOf('assets.squarespace.com') > 0) {
          Y.Squarespace.Analytics.trackInternal('system_audio_play_error', {
            readyState: this.get('readyState'),
            url: url });

        }
      } catch (e) {
      }

    },

    /**
      Returns a playtime string in the format 1:03/10:26
      @method _getPlaytimeString
      @private
      @return String the formatted playtime string or an empty string
    */
    _getPlaytimeString: function (trackPosition) {
      if (!this._audio) {
        return this.get('strings.durationPlaceHolder');
      }

      var duration = '0:00';
      var position = '0:00';

      duration = this._getMinSecString(this._audio.duration);

      if (trackPosition) {
        position = this._getMinSecString(trackPosition);
      }

      return position + ' / ' + duration;
    },

    /**
      Given a time in sec, convert it to a string like 2:04
    */
    _getMinSecString: function (time) {
      return humanizeDuration(time * 1000);
    },

    /**
      Shows an error message in the player in the case of a bad ready state
      @method _showError
      @private
    */
    _showError: function (message) {

      // update placeholder
      this.get('boundingBox').addClass('error');
      this.get('boundingBox').one('.placeholder').setContent('Error: ' + message);

    },

    // Sound manager sound event listeners
    /**
      Runs periodically while the sound manager sound plays, updating the progress and duration
      displays
      playState
      @method _onWhilePlaying
      @private
    */
    _onWhilePlaying: function (e) {

      // don't do anything if mid destroy
      if (this.get('destroyed')) {
        return;
      }

      // update current playtime
      var boundingBox = this.get('boundingBox');
      boundingBox.one('.duration').setContent(this._getPlaytimeString(e.position));

      // make sure ready state is right if this is playing
      var readyStates = this.getProperty('READY_STATES');
      if (this.get('readyState') !== readyStates.SOUND_LOADED) {
        this._set('readyState', readyStates.SOUND_LOADED);
      }

      // update progress
      var progressBarNode = boundingBox.one('.progress-inner');
      var progressPercentage = e.position / this._audio.duration * 100;

      boundingBox.removeClass('loading');

      if (progressBarNode) {
        progressBarNode.setStyle('width', progressPercentage + '%');
      }

    },

    /**
      Runs when the soundmanager sound stops, updating the
      playState
      @method _onStop
      @private
    */
    _onStop: function () {
      this._set('playState', this.getProperty('PLAY_STATES').STOPPED);
    },

    /**
      Runs when the soundmanager sound pauses, updating the
      playState
      @method _onPause
      @private
    */
    _onPause: function () {
      this._set('playState', this.getProperty('PLAY_STATES').PAUSED);
    },

    /**
      Runs when the soundmanager sound encounters a data error, most likely
      due to flash
      @method _onDataError
      @private
    */
    _onDataError: function () {
      this.stop();
      this._set('playState', this.getProperty('PLAY_STATES').STOPPED);
    },

    /**
      Runs when the sound manager sound load from url is complete
      @method _onLoad
      @private
      @param success {Boolean} The success flag from sound manager
    */
    _onLoad: function (success) {
      // sound manager will return fail in cases where the sound is loaded
      // from cache sometimes, so this is run on a timer to allow the onWhilePlaying
      // function to intercept it
      if (!success) {
        this._loadFailTimer = Y.later(10000, this, function () {
          this.stop();
          this._set('readyState', this.getProperty('READY_STATES').SOUND_LOAD_FAILED);
        });
      }
    } },


  // Static properties
  {
    CSS_PREFIX: 'sqs-audio-player',

    /**
      @property PLAY_STATES
      @description A set of constants representing the current play
        state of the sound
    */
    PLAY_STATES: {
      PLAYING: 'playing',
      PAUSED: 'paused',
      STOPPED: 'stopped' },


    /**
      @property READY_STATES
      @description A set of constants representing the ready state
        of the widget
    */
    READY_STATES: {
      INITIALIZED: 'initialized', // widget is initialized but no sound is loaded
      FLASH_BLOCKED: 'flash-blocked', // tried to load sound but could not because flash was blocked
      SECURITY_ERROR: 'security-error', // tried to load sound but could not because of a js security error
      SOUND_LOAD_FAILED: 'sound-load-failed', // loading the sound failed, possibly a bad url
      SOUND_LOADED: 'sound-loaded' // the sound was successfully loaded
    },

    TEMPLATE:
    '<div class="controls" tabindex="0"></div>' +
    '<div class="placeholder"></div>' +
    '<div class="progress" tabindex="0">' +
    '<div class="progress-inner">' +
    '<div class="scrubber"></div>' +
    '</div>' +
    '</div>' +
    '<div class="duration"></div>' +
    '<div class="volume" tabindex="0"></div>',

    ATTRS: {

      /**
        @attribute strings
        @description All the text used in this widget
        @type Object
      */
      strings: {
        value: {
          /**
            @attribute strings.placeholder
            @description Placeholder text for before the audio is played for the
              first time in this widget
            @type String
            @default Click to play...
          */
          placeholder: t("Click to play\u2026"),



          /**
            @attribute strings.download
            @description Text for download link
            @type String
            @default Download.
          */
          download: t("Download"),



          /**
            @attribute strings.durationPlaceHolder
            @description Placeholder text for duration before the audio is played
              for the fist time
            @type String
            @default Click to play...
          */
          durationPlaceHolder: '0:00 / 0:00',

          /**
            @attribute strings.loadFail
            @description Text to display when loading the audio at the given url
              fails
            @type String
            @default Unable to load sound at the URL
          */
          loadFail: t("Unable to load sound at the URL"),



          /**
            @attribute strings.soundManagerFlashFail
            @description Text to display when loading the audio at the given url
              fails because flash is blocked
            @type String
            @default Sorry, could not load because flash is blocked.
          */
          soundManagerFlashFail: t("Sorry, could not load because flash is blocked."),



          /**
            @attribute strings.soundManagerSecurityFail
            @description Text to display when loading the audio at the given url
              fails because of a js-flash security error
            @type String
            @default Sorry, there was a JavaScript security error.
          */
          soundManagerSecurityFail: t("Sorry, there was a JavaScript security error."),



          /**
            @attribute strings.soundManagerURLFail
            @description Text to display when loading the audio at the given url
              fails because it couldn't load the file at the URL
            @type String
            @default Sorry, there was a JavaScript security error.
          */
          soundManagerURLFail: t("Unable to access audio stream.") } },





      fixedUrl: {
        getter: function () {
          var url = this.get('url');

          if (url && url.indexOf(Static.SQUARESPACE_CONTEXT.appDomain) > 0) {
            // only modify sqsp domains, url could point to an external source
            url = url.replace(/^(http:\/\/|https:\/\/|\/\/)/i, window.location.protocol + '//');
          }

          return url;
        },
        readOnly: true },


      /**
        @attribute playState
        @description The current play state of the audio player (playing,
          paused, stopped). Constants available in Y.Squarespace.Widgets.AudioPlayer.PLAY_STATES
        @type Y.Squarespace.Widgets.AudioPlayer.PLAY_STATES
      */
      playState: {
        lazyAdd: false,
        valueFn: function () {
          return this.getProperty('PLAY_STATES').STOPPED;
        },
        readOnly: true },


      /**
        @attribute state
        @description The current readet state of the audio player (initialized,
          fash-blocked, security-error, sound-loaded). Constants available in
          Y.Squarespace.Widgets.AudioPlayer.READY_STATES
        @type Y.Squarespace.Widgets.AudioPlayer.READY_STATES
      */
      readyState: {
        lazyAdd: false,
        valueFn: function () {
          return this.getProperty('READY_STATES').INITIALIZED;
        },
        readOnly: true },


      /**
        @attribute url
        @description The url of the audio file to play.
        @type String
        @default null
      */
      url: {
        value: null },


      // volume stuff
      /**
        @attribute volume
        @description The current volume level, given as a percentage (0-100)
        @type Number
        @readOnly
        @default volume
      */
      volume: {
        validator: function (value) {
          if (value < 0 || value > 100 && __DEV__) {
            console.warn(this.name + ': Volume must be between 0 and 100, volume remains unchanged.');
          }
          return true;
        },
        value: 66 },


      /**
        @attribute volumeLevels
        @description The number of volume levels possible. This value is
          only settable during initialization configuration.
        @type Number
        @default 6
      */
      volumeLevels: {
        value: 6,
        writeOnce: 'initOnly' },


      /**
        @attribute stopOthersOnPlay
        @description Whether or not to stop all other soundmanager powered sounds
          on the page when play() is called
        @type Boolean
        @default true
      */
      stopOthersOnPlay: {
        value: true },


      // overriding default render value on initialization
      // to true, so this will render immediately on init
      render: {
        value: true } } });






  // ------------------------------------------------------------------------------------------------
  // Initialize
  // ------------------------------------------------------------------------------------------------

  var audioPlayerInstances = [];

  Y.Squarespace.Widgets.AudioPlayer.initializeNode = function (node) {
    // this should use Widget.getByNode to check for an existing AudioPlayer
    // instance/bounding box inside this thing
    if (node.get('children').size() > 0) {
      return;
    }

    if (!Y.Widget.getByNode(node.one('.' + Y.Squarespace.Widgets.AudioPlayer.CSS_PREFIX))) {
      var designStyle = Y.Object.getValue(node.getData(), 'design-style') || 'minimal';
      var audioPlayer;

      switch (designStyle) {
        case 'legacy':
          audioPlayer = new Y.Squarespace.Widgets.AudioPlayer({ render: node });
          break;

        default:
          audioPlayer = new Y.Squarespace.Widgets.AudioPlayerMinimal({ render: node });}


      audioPlayerInstances.push(audioPlayer);
    }
  };


  Y.config.win.Squarespace.onInitialize(Y, function () {
    Y.all('.sqs-audio-embed').each(Y.Squarespace.Widgets.AudioPlayer.initializeNode);
  });

  // ------------------------------------------------------------------------------------------------
  // Destroy
  // ------------------------------------------------------------------------------------------------


  Y.config.win.Squarespace.onDestroy(Y, function () {
    audioPlayerInstances.forEach(function (audioPlayer) {
      audioPlayer.destroy();
    });
    audioPlayerInstances.length = 0;
  });


}, '1.0', { requires: [
  'base',
  'event',
  'node',
  'squarespace-album-player',
  'squarespace-ss-widget',
  'squarespace-util',
  'squarespace-widgets-audio-player'] });