import moment from 'moment';
import padStart from 'lodash/padStart';

/*
 * Returns humanized time for a given duration (187000ms => 3:07)
 * @method humanizeDuration
 * @param {Number} duration in milliseconds
 *
 * NOTE: there is a moment plugin https://github.com/jsmreese/moment-duration-format with
 * an open issue/pr for pulling it into moment core: https://github.com/moment/moment/issues/1048.
 * Considering our current use (audioPlayer) I don't think it's worth pulling in. Might be worth reconsidering
 * if our needs for humanizeDuration get more complex, and refactoring this if the plugin does eventually
 * get pulled in to moment core.
 */
export default (ms = 0) => {
  const duration = moment.duration(ms);
  const seconds = duration.seconds();
  const minutes = Math.floor(duration.subtract(moment.duration(seconds, 's')).asMinutes());
  return minutes + ':' + padStart(seconds, 2, 0);
};
