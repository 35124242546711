import { t } from 'shared/i18n';
import TrackManager from '@sqs/site-rendering/TrackManager';
import { addQueryParam } from 'shared/utils/UrlUtils';
import registerKeyboardScrubber from 'shared/utils/KeyboardScrubber';

/**
 * @module squarespace-audio-player
 */
YUI.add('squarespace-widgets-audio-player', function (Y) {
  // TODO: change this module name when switching to the new player.

  var EQ_NUM_BARS = 100;
  var EQ_CHUNK_SIZE = 2;
  var EQ_MULTIPLIER = 75;

  /**
   * The AudioPlayerMinimal widget represents an AudioBlock.
   *
   *  About EQ data:
   *
   *    "it actually goes in and gets the chunks. what? are you writing down
   *    what i'm saying? uhm. it first figures out a chunk size.
   *    it's a fixed property. audio eq data is a 256 element array,
   *    we turn it into chunks (based on chunk size)
   *    now we have 128 valid eq chunks to use, each chunk representing
   *    an avg of that frequency range
   *    so we draw it out, but since the values are between 0
   *    and 1, we multiply it
   *    by 75, which is the avg height of the audio player
   *    the Math.min makes sure it has a min height"
   *                                                - Naz
   *
   * @class AudioPlayerMinimal
   * @constructor
   * @namespace Squarespace.Widgets
   * @extends Squarespace.Widgets.SSWidget
   */
  var AudioPlayerMinimal =
  Y.namespace('Squarespace.Widgets').AudioPlayerMinimal =
  Y.Base.create('audioPlayer', Y.Squarespace.Widgets.SSWidget, [], {

    initializer: function () {

      this.setAttrs(this.get('render').getData());

      if (this.get('title') === '' && this.get('url') !== '') {
        this.set('title', t("Untitled"));


      }

      this.set('showDownload', this.get('show-download') === 'true');
      this.set('colorTheme', this.get('color-theme'));
      this.set('artistName', this.get('author'));

      this._resizeEmitter = new Y.Squarespace.ResizeEmitter();

      this._registerTrack();
    },

    destructor: function () {

      if (Y.Lang.isValue(this._funk)) {
        this._funk.pause();
        this._funk = null;
      }

      if (Y.Lang.isValue(this._resizeEmitter)) {
        this._resizeEmitter.destroy();
        this._resizeEmitter = null;
      }

      this._pauseAudio();

      if (this._deregisterKeyboardScrubber) {
        this._deregisterKeyboardScrubber();
      }
    },

    renderUI: function () {

      AudioPlayerMinimal.superclass.renderUI.call(this);

      if (this.get('showDownload')) {
        this._renderDownloadLink();
      }

      this.get('boundingBox').addClass(this.get('colorTheme'));

    },

    bindUI: function () {

      var contentBox = this.get('contentBox');

      var actionButton = contentBox.one('.action');

      actionButton.on('click', this._onActionButtonClick, this);
      actionButton.on('keydown', function (e) {
        var enterPressed = e.keyCode === 13;
        var spacePressed = e.keyCode === 32;
        if (enterPressed || spacePressed) {
          // The other keydown handler on this element has to be called,
          // so stopImmediatePropagation shouldn't be called
          // (which would happen with e.halt(true)).
          e.halt();
          this._onActionButtonClick();
        }
      }, this);

      this.after('playingChange', this._playingChange, this);

      this.after('progressChange', function (e) {

        var progress = this.get('contentBox').one('.player .time .progress');
        progress.setContent(e.newVal);
        progress.addClass('loaded');

        this._updateTracker(this.get('position'));

      }, this);

      this.after('durationChange', function (e) {

        var duration = this.get('contentBox').one('.player .time .total');
        duration.setContent(e.newVal);
        duration.addClass('loaded');
        this.syncUI();

      }, this);

      this.after('titleChange', function (e) {
        this.get('contentBox').one('.player .labels .title').setContent(e.newVal);
      }, this);

      this.after('artistNameChange', function (e) {
        this.get('contentBox').one('.player .labels .artistName').setContent(e.newVal);
      }, this);

      this._registerEvent(this._resizeEmitter.on('resize:end', this.syncUI, this));

      var firstClick,
      firstKeyDown;

      function onFirstPlay(e) {
        e.halt();
        firstClick.detach();
        firstClick = null;
        firstKeyDown.detach();
        firstKeyDown = null;

        this._setupScrubber();
        this.set('firstPlay', false);
        this.set('playing', true);
      }

      firstClick = contentBox.on('click', function (e) {

        var wasDownloadNodeClicked = this.get('showDownload') && e.target === this._downloadNode.one('a');
        if (wasDownloadNodeClicked || !this.get('url')) {
          return;
        }

        if (this.get('firstPlay')) {
          onFirstPlay.call(this, e);
        }

      }, this);

      firstKeyDown = contentBox.one('.action').on('keydown', function (e) {
        var enterPressed = e.keyCode === 13;
        var spacePressed = e.keyCode === 32;
        if ((enterPressed || spacePressed) && this.get('firstPlay')) {
          onFirstPlay.call(this, e);
        }
      }, this);

    },

    syncUI: function () {

      var boundingBox = this.get('boundingBox');

      if (!boundingBox.getDOMNode()) {
        return;
      }

      if (boundingBox.ancestor('.sqs-editing')) {
        this._pauseAudio();
        this.set('playing', false);
      }

      var boxWidth = boundingBox.get('offsetWidth');

      boundingBox.one('.player .track').setStyle('width', boxWidth);
      boundingBox.toggleClass('playing', this.get('playing'));
      boundingBox.toggleClass('tiny', boxWidth < 300);
      boundingBox.toggleClass('no-artist', this.get('artistName') === '');
      boundingBox.toggleClass('first-play', this.get('firstPlay'));

      var titleNode = boundingBox.one('.player .labels .title');

      var titleOverflown = titleNode.get('offsetWidth') < titleNode.get('scrollWidth');
      titleNode.toggleClass('marquee-mark', titleOverflown);
    },

    _onActionButtonClick: function () {
      if (this.get('firstPlay')) {
        return;
      }
      this.set('playing', !this.get('playing'));
    },

    _renderNativeAudio: function () {
      var html = Y.Lang.sub(AudioPlayerMinimal.NATIVE_TEMPLATE, this.getAttrs());
      this.get('contentBox').replace(Y.Node.create(html));
    },

    /**
     * Add the download link markup if the user asked for it.
     *
     * @method _renderDownloadLink
     * @private
     */
    _renderDownloadLink: function () {

      var playerNode = this.get('contentBox').one('.player');
      var url = addQueryParam(this.get('url'), 'download', 'true');
      var markup = '<div class="download">' +
      '<a href="' + url + '" target="_blank">' + t("Download") +



      '</a>' +
      '</div>';
      this._downloadNode = playerNode.one('.secondary-controls').appendChild(markup);

      playerNode.addClass('download');

    },

    /**
     * Add the visualizer elements.
     *
     * @method _renderVisualizer
     * @private
     */
    _renderVisualizer: function () {

      var visualizerNode = this.get('contentBox').one('.visualizer');
      var markup = '';

      for (var i = 0; i < EQ_NUM_BARS; i++) {
        markup += '<div class="eq-bar"><div class="height"></div></div>';
      }

      visualizerNode.append(markup);

    },

    _pauseAudio: function () {
      if (TrackManager.player && TrackManager.player.pause) {
        TrackManager.player.pause();
      }
    },

    /**
     * Handles playing change events.
     *
     * @method _playingChange
     * @param {Event} e The change event.
     * @private
     */
    _playingChange: function (e) {

      var url = this.get('fixedUrl');

      if (Y.UA.ios) {
        window.location.href = url;
        return;
      }

      if (!Y.Lang.isValue(this._funk)) {
        this._registerTrack();
      }

      if (e.newVal) {
        this._funk.play();
      } else {
        this._funk.pause();
      }

      this.syncUI();

    },

    /**
     * Creates and configures the track via Audio.
     *
     * @method _registerTrack
     * @private
     */
    _registerTrack: function () {

      var onPlayingStopped = Y.bind(function () {
        this.set('playing', false);
      }, this);

      var onPlay = Y.bind(function () {
        this.set('playing', true);
        TrackManager.player.setVolume(this.get('volume') / 100);
      }, this);


      var onReady = Y.bind(function () {
        var duration = this._funk.duration;

        if (duration > 0) {
          duration = this._durationToStr(duration);
          this.set('duration', duration);
        }
      }, this);

      var stopAfterPlaybackEnds = Y.bind(function () {
        this.set('playing', false);
        this.set('position', 0);
      }, this);

      this._funk = TrackManager.createTrack(this.get('fixedUrl'));

      this._funk.on('ready', onReady);
      this._funk.on('ended', stopAfterPlaybackEnds);
      this._funk.on('play', onPlay);
      this._funk.on('pause', onPlayingStopped);
      this._funk.on('inactive', onPlayingStopped);
      this._funk.on('positionChange', Y.bind(this._whilePlaying, this));
    },

    /**
     * Handler for updates coming from Audio. Ticks while the song is playing.
     *
     * @method _whilePlaying
     * @private
     */
    _whilePlaying: function (track) {

      if (!this.get('playing')) {
        return;
      }

      this.set('position', track.position);
      this.set('progress', this._durationToStr(track.position));

      // NOTE: ignoring EQ bars until we can figure out a better way to
      // fetch the eq data
      // this._drawEQBars();

    },

    /**
     * Updates the width of the audio tracker (scrubber).
     *
     * @method _updateTracker
     * @private
     */
    _updateTracker: function (position) {

      var trackerNode = this.get('contentBox').one('.track .played');
      var width = position / this._funk.duration * 100;

      trackerNode.setStyle('width', width + '%');

    },

    /**
     * Reads the EQ data from the sound (if possible) and merges
     * the left and right EQ values to average them out.
     *
     * @method _mergeEQData
     * @return {Array} Array of averaged left/right EQ values.
     * @private
     */
    _mergeEQData: function () {
      // pretty sure theSound is undefined here and has never worked.
      var eqData = theSound.eqData; // eslint-disable-line no-undef
      var left = eqData.left;
      var right = eqData.right;
      var length = left.length;
      var merged = [];

      for (var i = 0; i < length; i++) {
        merged.push((parseFloat(left[i]) + parseFloat(right[i])) / 2);
      }

      return merged;

    },


    /**
     * Normalizes the merged EQ data to be used for setting
     * the heights of elements representing EQ bars.
     *
     * @method _calculateEQChunks
     * @return {Array} Array of height values derived from EQ data.
     * @private
     */
    _calculateEQChunks: function () {

      var merged = this._mergeEQData();
      var chunks = [];

      // @FIXME: no-restricted-globals surfaced this in IA-1245
      // I have no idea what we should be iterating through here.
      // It's almost certainly not the number of frames in the document (the global `length`).
      // Whatever this is meant to do probably doesn't work as expected.
      for (var i = 0; i < length; i += EQ_CHUNK_SIZE) {

        var total = 0;

        Y.Array.each(merged.slice(i, i + EQ_CHUNK_SIZE), function (chunk) {// eslint-disable-line no-loop-func
          total += chunk;
        });

        chunks.push(total / EQ_CHUNK_SIZE * EQ_MULTIPLIER);

      }

      return chunks;

    },

    /**
     * Sets the heights of all the eq-bar elements to reflect
     * the chunked values.
     *
     * @method _drawEQBars
     * @private
     */
    _drawEQBars: function () {

      var eqBars = this.get('contentBox').all('.eq-bar .height');
      var chunks = this._calculateEQChunks();

      Y.Array.each(chunks, function (item, idx) {

        var el = eqBars.item(idx);

        if (Y.Lang.isValue(el)) {
          el.setStyle('height', Math.min(45, chunks[idx]));
        }

      }, this);

      this.syncUI();

    },

    /**
     * Setup the drag on the track icon.
     *
     * @method _setupScrubber
     * @private
     */
    _setupScrubber: function () {

      var shimNode = Y.Node.create('<div class="scrubber-shim"></div>');
      var trackNodeRegion;

      shimNode.setStyles({
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        zIndex: Math.pow(10, 7),
        cursor: 'none' });


      var track = this.get('contentBox').one('.player .track');

      track.on('mousedown', function (e) {
        this._updatePosition(e);

        var origVolume = TrackManager.player.getVolume();
        trackNodeRegion = this.get('contentBox').one('.track').get('region');

        // // lower the volume while scrubbing cuz it sounds cool and stuff
        if (this._funk.isActive) {
          TrackManager.player.setVolume(origVolume * 0.25);
        }
        Y.one('body').append(shimNode);

        shimNode.once('mouseup', function () {
          TrackManager.player.setVolume(origVolume);
          shimNode.remove();
        }, this);
      }, this);

      shimNode.on('mousemove', function (e) {
        var position = this._getRelativePosition(trackNodeRegion, e.pageX);
        this._funk.seek(position);
        if (!this.get('playing')) {
          this.set('progress', this._durationToStr(position));
          this.set('position', position);
        }

      }, this);

      // The track should only become keyboard focusable
      // when it's set up.
      track.setAttribute('tabindex', 0);

      this._deregisterKeyboardScrubber = registerKeyboardScrubber({
        element: track,
        getDuration: function () {
          return this._funk.duration;
        }.bind(this),
        getPosition: function () {
          return this.get('position');
        }.bind(this),
        isPlaying: function () {
          return this.get('playing');
        }.bind(this),
        play: function () {
          this.set('playing', true);
        }.bind(this),
        pause: function () {
          this.set('playing', false);
        }.bind(this),
        setPosition: function (position) {
          // There is a refactor opportunity here,
          // this code is almost shared with mouse scrubbing.
          // But there is already an _updatePosition method
          // that does something different,
          // so this duplication of logic seems
          // okay compared to trying to figure
          // out all the ins and outs of this class.
          this._funk.seek(position);
          this.set('progress', this._durationToStr(position));
          this.set('position', position);
        }.bind(this) });

    },

    /**
     * Updates the position of the song based on where
     * the user clicks in the track area.
     *
     * @method _updatePosition
     * @param {Event} e The click event.
     * @private
     */
    _updatePosition: function (e) {

      if (this.get('firstPlay')) {
        return;
      }

      var region = this.get('contentBox').one('.track').get('region');
      var trackerNode = this.get('contentBox').one('.track .played .icon');
      var offsetWidth = trackerNode.get('offsetWidth') / 2;

      this._funk.seek(this._getRelativePosition(region, e.pageX - offsetWidth));

    },

    /**
     * Converts a duration to a time string.
     *
     * @method _durationToStr
     * @param {Number} duration A duration value in seconds.
     * @return {String} A cleaned string version in HH:MM:SS.
     * @private
     */
    _durationToStr: function (duration) {

      var seconds = Math.floor(duration % 60);
      var minutes = Math.floor(duration / 60);
      var hours = Math.floor(minutes / 60);

      if (hours > 0) {
        minutes = minutes % 60;
      }

      var string = '';
      if (hours > 0) {
        string += hours + ':';
        if (minutes < 10) {
          string += '0';
        }
      }

      if (seconds < 10) {
        seconds = '0' + seconds;
      }

      string += minutes + ':' + seconds;

      return string;

    },

    /**
     * Gets the targeted time position for the track based on the
     * position change on an element.
     *
     * @method _getRelativePosition
     * @param {Node} region The base node containing the track information.
     * @param {Number} x The new desired coordinate position.
     * @return {Number} The new desired song position in milliseconds.
     * @private
     */
    _getRelativePosition: function (region, x) {

      return this._funk.duration * Math.min(1, Math.max(0, (x - region.left) / region.width));

    },

    /**
     * Updates the volume in the UI and sets it for the sound.
     *
     * @method _selectVolume
     * @param {Number} volume The desired volume.
     * @private
     */
    _selectVolume: function (volume) {

      var volumeNodes = this.get('contentBox').all('.player .volume .bar');

      volumeNodes.removeClass('active');
      volumeNodes.filter(function (node) {
        var nodeVol = parseInt(node.getAttribute('data-level'), 10);
        return nodeVol <= volume;
      }).addClass('active');

      Y.Squarespace.Singletons.Audio.set('volume', volume);

    } },

  {
    CSS_PREFIX: 'sqs-widgets-audio-player',
    HANDLEBARS_TEMPLATE: 'block-audio-player.html',
    ATTRS: {

      /**
       * Changes the url to use https if it's coming from squarespace.com
       *
       * @attribute fixedUrl
       * @type String
       * @readOnly
       */
      fixedUrl: {
        getter: function () {
          var url = this.get('url');

          if (url.indexOf(Static.SQUARESPACE_CONTEXT.appDomain) > 0) {
            // only modify sqsp domains, url could point to an external source
            url = url.replace(/^(http:\/\/|https:\/\/|\/\/)/i, window.location.protocol + '//');
          }

          return url;
        },
        readOnly: true },


      /**
       * Determines if the user has played this track yet.
       *
       * @attribute firstPlay
       * @type Booelan
       * @default true
       */
      firstPlay: {
        value: true,
        validator: Y.Squarespace.AttrValidators.isBoolean },


      /**
       * The second position of the track. Used when swapping back to a song
       * after listening to a different one.
       *
       * @attribute position
       * @type Number
       * @default 0
       */
      position: {
        value: 0,
        validator: Y.Squarespace.AttrValidators.isNumber },


      /**
       * Tracks the progress of the song while playing in MM:SS format.
       *
       * @attribute progress
       * @type String
       */
      progress: {
        validator: Y.Squarespace.AttrValidators.isNullOrString },


      /**
       * The duration of the track in MM:SS format. Gets loaded
       * when the song has finished downloading.
       *
       * @attribute duration
       * @type String
       */
      duration: {
        validator: Y.Squarespace.AttrValidators.isNullOrString },


      /**
       * The song title.
       *
       * @attribute title
       * @type String
       * @default ''
       */
      title: {
        value: '',
        validator: Y.Squarespace.AttrValidators.isString },


      /**
       * The name of the artist.
       *
       * @attribute artistName
       * @type String
       * @default ''
       */
      artistName: {
        value: '',
        validator: Y.Squarespace.AttrValidators.isString },


      /**
       * Indicates whether or not the song is currently playing.
       *
       * @attribute playing
       * @type Boolean
       * @default false
       */
      playing: {
        value: false,
        validator: Y.Squarespace.AttrValidators.isBoolean },


      /**
       * The current selected volume. Can be 0-100 in increments of 25.
       *
       * @attribute volume
       * @type Number
       * @default 100
       */
      volume: {
        value: 100,
        validator: Y.Squarespace.AttrValidators.isNumber } } });




}, '1.0', {
  requires: [
  'base',
  'node',
  'squarespace-block-audio-player-template',
  'squarespace-dom-emitters-resize',
  'squarespace-ss-widget',
  'widget'] });